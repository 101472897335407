export const toArray = <T,>(items: T | T[]) =>
  Array.isArray(items) ? items : [items];

export const domain = (url: string) => {
  try {
    const { hostname } = new URL(url);
    return hostname;
  } catch (error) {
    return url;
  }
};

export const isProduction = (): boolean =>
  process.env.NODE_ENV === 'production';

export const debug = (message?: any, ...optionalParams: any[]): void => {
  // eslint-disable-next-line no-console
  if (!isProduction()) console.log(message, ...optionalParams);
};

export const isEmptyObject = (object: object) =>
  Object.values(object).every((x) => x === null || x === '');
