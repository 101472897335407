import { forwardRef } from 'react';

import type { ComponentPropsWithRef, ElementType, Ref } from 'react';
import type { TypeProseProps } from './types';

/**
 * Prose
 */
const ProseBase = <T extends ElementType = 'div'>(
  {
    className = '',
    as,
    html,
    ...props
  }: TypeProseProps<T> &
    Omit<ComponentPropsWithRef<T>, keyof TypeProseProps<T>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: Ref<any>
) => {
  const HTMLTag = as || 'div';

  return (
    <HTMLTag
      {...props}
      ref={ref}
      className={`prose max-w-none font-primary font-normal text-inherit prose-headings:text-text-primary prose-a:break-words prose-a:font-bold prose-a:text-state-primary-default hover:prose-a:text-state-primary-hover prose-strong:text-text-primary ${className}`}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export const Prose = forwardRef(ProseBase);
