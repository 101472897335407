import { forwardRef } from 'react';
import { oneLine } from 'common-tags';

import type { ComponentPropsWithRef, ElementType, Ref } from 'react';
import '@/lib/utils/forwardRef';
import type { ButtonProps, TypeButtonSize, TypeButtonVariant } from './types';

const VARIANT_MAPS: Record<TypeButtonVariant, string> = {
  primary:
    'bg-state-primary-default text-text-alternative hover:bg-state-primary-hover border-state-primary-default hover:border-state-primary-hover disabled:bg-state-primary-disabled disabled:border-state-primary-disabled aria-disabled:bg-state-primary-disabled aria-disabled:border-state-primary-disabled',
  secondary:
    'bg-state-secondary-default text-text-alternative hover:bg-state-secondary-hover border-state-secondary-default hover:border-state-secondary-hover disabled:bg-state-secondary-disabled disabled:text-primary-400 disabled:border-state-secondary-disabled aria-disabled:bg-state-secondary-disabled aria-disabled:border-state-secondary-disabled',
  outline:
    'border-state-primary-default text-state-primary-default hover:text-state-primary-hover hover:border-state-primary-hover disabled:border-state-primary-disabled disabled:text-state-primary-disabled aria-disabled:border-state-primary-disabled aria-disabled:text-state-primary-disabled',
  alternative: 'border-white text-white hover:opacity-70 disabled:opacity-20',
};

const SIZE_MAPS: Record<TypeButtonSize, string> = {
  small: 'px-6 py-2 text-button--small',
  large: 'px-10 py-3 text-button--large',
};

const ButtonBase = <T extends ElementType = 'button'>(
  {
    className = '',
    variant,
    size,
    as,
    children,
    ...props
  }: ButtonProps<T> & Omit<ComponentPropsWithRef<T>, keyof ButtonProps<T>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: Ref<any>
) => {
  const HTMLTag = as || 'button';

  return (
    <HTMLTag
      className={oneLine` 
        text-button flex w-auto cursor-pointer items-center
        justify-center gap-x-3 rounded-full border text-center
         transition-colors duration-300 ease-in-out
        disabled:pointer-events-none aria-disabled:pointer-events-none
        ${VARIANT_MAPS[variant]}
        ${SIZE_MAPS[size]}
        ${className ?? ''}
      `}
      ref={ref}
      {...props}
    >
      {children}
    </HTMLTag>
  );
};

export const Button = forwardRef(ButtonBase);
