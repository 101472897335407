import { forwardRef } from 'react';
import { oneLine } from 'common-tags';

import { toArray } from '@/utils/utils';

import type { ComponentPropsWithRef, ElementType, Ref } from 'react';
import type { TypeContainerProps } from './index';

/**
 * Container
 */
const ContainerBase = <T extends ElementType = 'div'>(
  {
    index,
    as,
    className = '',
    backgroundColorTop: bg,
    backgroundColorBottom: bgBottom,
    cutLevel,
    cutLevelMobile,
    children,
    hasCutLevel,
    overflows = false,
    backgroundColorBottomMobile: bgBottomMobile,
    backgroundColorTopMobile: bgMobile,
    id,
    ...props
  }: TypeContainerProps<T> &
    Omit<ComponentPropsWithRef<T>, keyof TypeContainerProps<T>>,
  ref: Ref<any>
) => {
  const HTMLTag = as || 'div';

  return (
    <>
      {hasCutLevel && (
        <style>
          {`
            :root{
              --linear-gradient-${index}: linear-gradient(
                var(--tw-color-${bgMobile}) ${cutLevelMobile ?? cutLevel}%,
                var(--tw-color-${bgBottomMobile}) ${cutLevelMobile ?? cutLevel}%
              );

              @media screen (min-width: 768px) {
                --linear-gradient-${index}: linear-gradient(
                  var(--tw-color-${bg}) ${cutLevel}%,
                  var(--tw-color-${bgBottom}) ${cutLevel}%
                );
              }
            }
          `}
        </style>
      )}

      <HTMLTag
        ref={ref}
        id={id}
        {...((hasCutLevel || bg) && {
          style: hasCutLevel
            ? { background: `var(--linear-gradient-${index})` }
            : { backgroundColor: `var(--tw-color-${bg})` },
        })}
        {...props}
      >
        <div
          className={oneLine`
            w-auto py-10 lg:py-20
            ${
              overflows
                ? 'mx-5 overflow-visible md:ml-[max(1.25rem,_calc((100%_-_var(--wrapper-size)_+_2.5rem)_/_2))]'
                : 'px-5 md:max-w-wrapper md:mx-auto'
            }
            ${className}
          `}
        >
          {toArray(children).map((child, index) => (
            <child.type key={index} {...child?.props} />
          ))}
        </div>
      </HTMLTag>
    </>
  );
};

export const Container = forwardRef(ContainerBase);
