import useTranslation from 'next-translate/useTranslation';
import NextLink from 'next/link';

import { Button, Container, Prose } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypeErrorPageProps } from './types';

export const ErrorPage: FunctionComponent<TypeErrorPageProps> = ({
  statusCode,
}) => {
  const { t } = useTranslation('common');

  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-ignore */
  const labels: {
    title: string;
    subtitle: string;
    description: string;
    buttonText: string;
  } = t(`components.templates.pageError.${statusCode}`, 'common', {
    returnObjects: true,
  });

  return (
    <Container
      backgroundColorTop="surface-01"
      className="flex h-screen w-auto items-center justify-center md:w-full"
    >
      <div className="flex flex-col items-center justify-center gap-12 md:gap-16">
        <div className="flex flex-col items-center justify-center gap-6 md:gap-4">
          <h1
            className="text-heading--h1 w-full py-4 text-start md:text-heading--h1 md:w-1/2 md:text-center"
            dangerouslySetInnerHTML={{ __html: labels.title }}
          />
          <h2
            className="text-heading text-heading-mobile--h2 text-start text-primary-400 md:text-heading--h2 md:w-1/2 md:text-center"
            dangerouslySetInnerHTML={{ __html: labels.subtitle }}
          />
          <Prose
            html={labels.description}
            className="text-start text-primary-400 md:mt-8 md:w-1/2 md:text-center"
          />
        </div>
        <NextLink href="/" passHref prefetch={false}>
          <Button
            className="w-full md:w-1/2 md:max-w-fit"
            as="a"
            size="small"
            variant="outline"
          >
            {labels.buttonText}
          </Button>
        </NextLink>
      </div>
    </Container>
  );
};

ErrorPage.displayName = 'ErrorPage';
